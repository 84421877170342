

input[type="text"], textarea {
  background-color: inherit;
}

.applet {
  padding: 1em;
  height: 100%;
  width: 100%;
}

.applet-container {
  width: 100%;
  margin-top: 3em;
  margin-bottom: 3em;
}

.applet-container>p {
  text-align: center;
  justify-content: center;
}

.applet>h2 {
  text-align: center;
}


.code {
  display: block;
  unicode-bidi: embed;
  font-family: monospace;
  white-space: pre;
  padding: 1em;
  margin: 10px;
}

error {
  color: red;
}
