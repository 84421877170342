.imageGrid > img {
    max-width: 90%;
    max-height: 250px;
}

.imageGrid {
    padding: 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: 3vw;
    margin-left: 3vw;
    gap: 1.5em;
}


