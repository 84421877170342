#headshot {
    position: center;
    padding-top: 25%;
    width: 25%;
    max-width: 90%;
    height: 25%;
    max-height: 90%;
    background-image: url("../../public/pics/headshot2.jpg");
    border-radius: 50%;
    background-size: cover;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    transform: scale(-1, 1)
}

ul#interests {
    text-align: left;
    display: table;
    /* margin: 0 auto; */
}

.about-me {
    align-items: center;
    align-content: center;
    /* margin: auto; */
    display: flex;
    justify-content: space-between;

}