
.grid-block {
    width: 5px;
    height: 5px;
    /* margin: 2px; */
}

.theme-item:hover {
    outline: 1px solid red;
}

.themes-display {
    cursor: pointer;
    /* margin-left: 30px; */
    flex-wrap: wrap;
    display: flex;
    /* outline: 2px solid blue; */
}

.theme-item {
    /* margin: 0.4em; */
    /* padding: 0.4em; */
    /* outline: 2px solid red; */
    width: 25px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0.3em; */
    margin-left: 0.3em;
    margin-right: 0.3em;
    /* align-content: space-between; */
}


