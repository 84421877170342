.loans-app {
    padding: 5px;
}


.grid {
    flex: 1;
    margin-left: 5em;
  }

.grid-element {
    height: 0;
    width: 5%;
    padding-bottom: 5%;
}

.grid-element:hover {
    opacity: 0.5;
}
