#creds img {
    max-width: 20px;
    height: 20px;
}

#creds a {
    background-color: transparent;
    text-decoration: none;
}

#creds div.ul {
    margin-left: 2em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#creds div.li {
    margin: 2px;
    padding: 2px;
    min-width: 150px;
    flex: 1;
}

#creds blockquote {
    text-align: center;
}

#creds {
    align-content: center;
}
