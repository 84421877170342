.tile {
    outline: 2px solid grey;
    /* height: 200px; */
    /* width: 200px; */
    max-height: 200px;
    max-width: 200px;
    margin: auto;
}

.tile-content {
    font-size: 6em;
    margin: auto;
}

@media (max-width: 720px) {
    .tile {
        outline: 2px solid grey;
        /* height: 200px; */
        /* width: 200px; */
        max-height: 50px;
        max-width: 50px;
        margin: auto;
    }
    
    .tile-content {
        font-size: 2em;
        margin: auto;
    }
}

.tile:hover {
    background-color: rgba(255, 255, 255, 0.5)
}

.gameboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.memory-game {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}


.controls {
    display: inline-block;
    opacity: 0.5;
}

.controls:hover {
    opacity: 1 !important;
}

.memory-controls {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}
